import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import 'array-from-polyfill';
import 'fetch-polyfill';

import { onDomReady } from '@grrr/ready';
import { enhance, handle } from '@grrr/hansel';
import { autoSelectCountryEnhancer } from './modules/auto-select-country';
import { slideNextHandler } from './modules/slide';
import { autoTargetBlankEnhancer } from './modules/auto-target-blank';

import {
    cleanWarningsAfterNewInputEnhancer,
    hideFieldsOnSelectionEnhancer,
} from './modules/form-helpers';

import {
    formatPhoneNumberEnhancer,
    showElementHandler,
    totalBondValueEnhancer,
} from './modules/subscribe-as-guest';
import {
    calculateTotalEnhancer,
    numberFieldManipulatorHandler,
    showGetNewCodeEnhancer,
} from './modules/subscribe-by-extending';
import { default as subMenu } from './modules/sub-menu';

const addClassOnLoad = function (el) {
    const className = el.getAttribute('data-class');
    el.classList.add(`js-${className}`);
};

/**
 * Babel will replace env var SENTRY_DSN by it's value.
 */
if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

onDomReady(() => {
    subMenu.init();
    enhance(document.documentElement, {
        addClassOnLoad,
        autoSelectCountryEnhancer,
        totalBondValueEnhancer,
        hideFieldsOnSelectionEnhancer,
        formatPhoneNumberEnhancer,
        calculateTotalEnhancer,
        showGetNewCodeEnhancer,
        cleanWarningsAfterNewInputEnhancer,
        autoTargetBlankEnhancer,
    });

    handle(document.documentElement, {
        slideNextHandler,
        showElementHandler,
        numberFieldManipulatorHandler,
    });
});
