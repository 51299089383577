module.exports = (function () {
    return {
        init(options) {
            const navButton = document.querySelector('.js-menu-button');
            const subNav = document.querySelector('.js-sub-navigation');

            // toggle classes when menu button is clicked
            function toggleClass() {
                navButton.classList.toggle('is-clicked');
                subNav.classList.toggle('is-expanded');

                if (!subNav.classList.contains('is-expanded')) {
                    navButton.blur();
                }
            }

            navButton.addEventListener('click', toggleClass);
        },
    };
})();
