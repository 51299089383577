import { slideDown, slideStop, slideUp } from 'slide-anim';

let hideOnSelectionFormFields;
let allFormFields;

const showAllFields = function () {
    for (let j = 0; j < allFormFields.length; j += 1) {
        slideDown(allFormFields[j]);
    }
};

const checkIfSelected = function (selectedItem, value) {
    if (selectedItem.nodeName.toLowerCase() === 'select') {
        return selectedItem.value === value;
    }
    return selectedItem.value === value && selectedItem.checked;
};

const executeHiding = function (selector, fieldName) {
    showAllFields();
    const valueThatHidesFields = hideOnSelectionFormFields[fieldName];
    Object.keys(valueThatHidesFields).forEach(function (value) {
        Object.keys(selector).forEach(function (k) {
            if (checkIfSelected(selector[k], value)) {
                const otherFields = valueThatHidesFields[value];
                Object.keys(otherFields).forEach(function (otherField) {
                    const item = document.querySelector(
                        `#field-${otherFields[otherField]}`
                    );
                    slideStop(item);
                    slideUp(item);
                });
            }
        });
    });
};

const initHideFields = function (el) {
    Object.keys(hideOnSelectionFormFields).forEach(function (fieldName) {
        const fieldSelector = el.querySelectorAll(`[name=${fieldName}]`);
        // init on load
        executeHiding(fieldSelector, fieldName);
        // listen for changes
        for (let k = 0; k < fieldSelector.length; k += 1) {
            fieldSelector[k].addEventListener('change', function (e) {
                executeHiding(fieldSelector, fieldName);
            });
        }
    });
};

export const cleanWarningsAfterNewInputEnhancer = function (el) {
    const invalidFields = el.querySelectorAll('.form__input--invalid');
    Array.from(invalidFields, function (input) {
        input.addEventListener('change', function () {
            if (this.value.length > 0) {
                this.classList.remove('form__input--invalid');
                slideUp(this.nextElementSibling);
            }
        });
    });
};

export const hideFieldsOnSelectionEnhancer = function (el) {
    const fieldsJson = el.getAttribute('data-fields');
    if (typeof fieldsJson !== 'undefined') {
        hideOnSelectionFormFields = JSON.parse(fieldsJson);
        allFormFields = document.querySelectorAll('.form__field');
        initHideFields(el);
    }
};
